.contractTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: var(--mantine-radius-md);
  height: 120px;
  width: 160px;
  background-color: var(--mantine-color-dark-6);
  border: 1px solid var(--mantine-color-dark-5);
  transition:
    box-shadow 150ms ease,
    transform 100ms ease;
}

.contractTile:hover {
  box-shadow: var(--mantine-shadow-md);
  transform: scale(1.05);
}